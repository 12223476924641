import { useContext } from 'react';
import AppCtx from '../../../../../AppCtx';
import { Logger } from '../../../../../utils/Logger';
import './ChromaticityDataView.scss';

function ChromaticityDataView(props: any) {

  Logger.debug('ChromaticityDataView component render.');
  const appCtxRef = useContext(AppCtx);
  const clickAction = (a: any, flag: any, data: any, allow: boolean) => {
    if (a && flag && allow) {
      data !== null ? a(data) : a();
    }
  }
  
  return (
    <div className='app-data-view'>
      {props.title && <p className='app-data-view-title'>{props.title}
            {props.headerIcon && 
              <img src={props.headerIcon} className='app-cursor-pointer' alt='' onClick={() => {clickAction(props.headerIconClick, props.headerIcon, null, true)}}/>
            }</p>}

      <div className='app-data-view-content-scroll'>
        <div className='app-data-view-content'>
          {props.data?.length > 0 && props.data.map(function(row: any, rowIdx: number) {
            return(
              <div key={rowIdx}  onClick={() => {clickAction(props.editRowClick, props.editRow, rowIdx, appCtxRef?.session?.details?.identifier === row['createdBy'])}}>
               
                {  row['type'] === 'rectangle' &&
                  <div className={props.editRow ? 'app-data-view-row app-data-view-row-hover' : 'app-data-view-row'}>
                    <span className='app-data-view-col'>Rectangle<br/><b>{row['name']}</b></span>
                    <span className='app-data-view-col'>A<br/>x: {row['x1']}<br/>y: {row['y1']}</span>
                    <span className='app-data-view-col'>B<br/>x: {row['x2']}<br/>y: {row['y2']}</span>
                    <span className='app-data-view-col'>C<br/>x: {row['x3']}<br/>y: {row['y3']}</span>
                    <span className='app-data-view-col'>D<br/>x: {row['x4']}<br/>y: {row['y4']}</span>

                    {row['createdBy'] === appCtxRef?.session?.details?.identifier &&
                    <img src={props.rowIcon} className='app-cursor-pointer' alt='' onClick={($event: any) => {clickAction(props.rowIconClick, props.rowIcon, rowIdx, true); $event.stopPropagation();}}/>}
                  </div>

                }

                {  row['type'] === 'ellipse' &&
                  <div className={props.editRow ? 'app-data-view-row app-data-view-row-hover' : 'app-data-view-row'}>
                    <span className='app-data-view-col'>Ellipse<br/><b>{row['name']}</b></span>
                    <span className='app-data-view-col'>Center<br/>x: {row['x1']}<br/>y: {row['y1']}</span>
                    <span className='app-data-view-col'>Radius<br/>x: {row['x2']}<br/>y: {row['y2']}</span>
                    <span className='app-data-view-col'>Angle<br/>{row['x3']} deg</span>
                    {appCtxRef?.session?.details?.identifier === row['createdBy'] &&
                    <img src={props.rowIcon} className='app-cursor-pointer' alt='' onClick={($event: any) => {clickAction(props.rowIconClick, props.rowIcon, rowIdx, true); $event.stopPropagation();}}/>}
                  </div>

                }
                
              </div>
            );
          })}

          {props.keys && props.data?.length === 0 &&
            <div className='app-text-gray mt10 ml15'>No Enteries</div>
          }
        </div>
      </div>
    </div>
  );
}
export default ChromaticityDataView;

