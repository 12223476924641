import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import AppCtx from '../../AppCtx';
import AddManufacturer from '../../components/manufacturers/add-manufacturer/AddManufacturer';
import Color from '../../components/manufacturers/color/Color';
import DelManufacturer from '../../components/manufacturers/del-manufacturer/DelManufacturer';
import Loader from '../../components/ui-elements/loader/Loader';
import NavMap from '../../components/ui-elements/nav-map/NavMap';
import { APP_FEATURES } from '../../core/AppConstants';
import { ManufacturersService } from '../../services/ManufacturersService';
import { DateUtils } from '../../utils/DateUtils';
import { Logger } from '../../utils/Logger';
import './Manufacturers.scss';
import ManufacturersCtx from './ManufacturersCtx';

function Manufacturers() {

  Logger.debug('Manufacturers component render.');

  const appCtxRef = useContext(AppCtx);
  const [loading, setLoading] = useState<any>(true);
  const [manufacturers, setManufacturers] = useState<any>([]);
  const [colors, setColors] = useState<any>([]);
  const [hideModileNav, setSideModileNav] = useState<boolean>(true);
  const [manufacturerSelected, setManufacturerSelected] = useState<any>(null);
  const modalNavMapRef = useRef<any>();
  const [navFilter, setNavFilter] = useState<any>('');

  const ManufacturersCtxValue = {
    manufacturers, setManufacturers,colors, setColors
  };
  
  //const [UPDATE_PERMISSION] = useState<boolean>(appCtxRef?.session?.features[APP_FEATURES.GeneralManufacturerUpdate]);
  const [READ_PERMISSION] = useState<boolean>(appCtxRef?.session?.features[APP_FEATURES.GeneralManufacturer]);

  const load = useCallback(() => {
    ManufacturersService.all().then((resp: any) => {
      setManufacturers(resp.data);
      appCtxRef.setCore({...appCtxRef.core, manufacturers: resp.data});

      if (manufacturerSelected) {
        for (const it of resp.data) {
          if (manufacturerSelected.id === it.id && manufacturerSelected.name !== it.name) {
            setManufacturerSelected(it);
            break;
          }
        }
      }
      setLoading(false);
    });
  }, [manufacturerSelected, appCtxRef]);
  
  const onSelect = (item: any) => {
    setManufacturerSelected(item);
    setSideModileNav(true);
  };
  
  const onFilter = (item: any) => {
    setNavFilter(item);
  };

  const setFilterProp = useCallback((manufacturers: any, colors: any) => {
    manufacturers?.forEach((m: any) => {
      m.searchParams = [];
      const filteredColors = colors?.filter((c:any) => c.manufacturer?.id === m.id);
      filteredColors?.forEach((c: any) => {
        m.searchParams.push(c.name, c.scmInternalId);
      });
    });

    return manufacturers;
  }, [manufacturers, colors]);

  useEffect(() => {
    modalNavMapRef?.current?.load(manufacturers);
  }, [manufacturers]);

  useEffect(() => {
    if (appCtxRef.core.manufacturers) {
      ManufacturersService.allColors().then((resp: any) => {
        const manufacturers = setFilterProp(appCtxRef.core.manufacturers, resp.data);
        setManufacturers(manufacturers);
        
        const timeoutRef = setTimeout(() => {
          setLoading(false);
        }, 200);
        return () => {
          clearTimeout(timeoutRef);
        };
      });
    } else {
      ManufacturersService.all().then((resp: any) => {
        setManufacturers(resp.data);
        appCtxRef.setCore({...appCtxRef.core, manufacturers: resp.data});
        setLoading(false);
      });
    }
  }, [appCtxRef]);
  
  if (!READ_PERMISSION) {
    return (
      <div className="app-manufacturers">
      <span className='app-no-permission-content'>
        You don't have pemission to access this page.
        <br />
        Please contact your administrator.
      </span>
      </div>
    );
  }
  
  return (
    <ManufacturersCtx.Provider value={ManufacturersCtxValue}>
      <div className="app-manufacturers">

        {manufacturerSelected && <div className='app-manufacturers-content'>

          <div className='app-manufacturers-header'>

            <p className='app-manufacturers-header-mobile'>
              <span className='app-manufacturers-header-nav-map-switch-mobile' onClick={() => {setSideModileNav(!hideModileNav)}}>
                <img src='/assets/img/icons/grid-black.svg' alt='' />
              </span>
            </p>

            <div className='app-manufacturers-header-title'>
              <span className='app-manufacturers-header-title-text mb10'>
                Manufacturer:
                <span className='app-manufacturers-header-title-text-active'> {manufacturerSelected?.name}</span>
              </span>
              <AddManufacturer onSave={load} edit={manufacturerSelected}></AddManufacturer>
              { appCtxRef?.session?.details?.identifier === manufacturerSelected?.createdBy && <DelManufacturer delId={manufacturerSelected?.id} onSave={load}></DelManufacturer>}
            </div> 
            <p className='app-manufacturers-header-subtitle'>
              Creation Date: <b className='mr20'>{DateUtils.format(manufacturerSelected?.createDate, '')}</b>
              Update Date: <b className='mr20'>{DateUtils.format(manufacturerSelected?.updateDate, '')}</b>
            </p>
          
          </div>

          <Color manufacturer={manufacturerSelected}></Color>

        </div>}

        <div className={`app-manufacturers-nav-map ${hideModileNav ? "app-manufacturers-nav-map-hide-mobile" : ""}`} >
          <div className='app-manufacturers-nav-map-wrapper'>
            <NavMap 
              ref={modalNavMapRef}
              title='Manufacturers'
              selectProp='id' 
              presentProp='name' 
              filter={true} 
              selectFirst={true}
              onSelect={onSelect}
              onFilter={onFilter}
              filterAll={true}
            />
          </div>
          
          <div className='app-manufacturers-nav-map-buttons'>
            <AddManufacturer onSave={load} navFilter={navFilter}></AddManufacturer>
          </div>
        </div>

        {loading && !appCtxRef.ui.loading && <Loader />}
        
      </div>
    </ManufacturersCtx.Provider>
  );
}

export default Manufacturers;
