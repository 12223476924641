import { useContext } from 'react';
import AppCtx from '../../../AppCtx';
import { Logger } from '../../../utils/Logger';
import './DataView.scss';

function DataView(props: any) {

  Logger.debug('DataView component render.');

  const appCtxRef = useContext(AppCtx);
  const clickAction = (a: any, flag: any, data: any, allow: boolean) => {
    if (a && flag && allow) {
      data !== null ? a(data) : a();
    }
  }
  
  return (
    <div className='app-data-view'>
      {props.title && <p className='app-data-view-title'>{props.title}
            {props.headerIcon && 
              <img src={props.headerIcon} className='app-cursor-pointer' alt='' onClick={() => {clickAction(props.headerIconClick, props.headerIcon, null, true)}}/>
            }</p>}

      <div className='app-data-view-content-scroll'>
        <div className='app-data-view-content'>
          {props.header && props.data?.length > 0 &&
            <div className='app-data-view-row'>
              {props.header.map(function(col: any, colIdx: number) {
                return (
                  <span key={colIdx} className='app-data-view-col'><b>{col}</b></span>
                );
              })}
            </div>
          }
          {props.keys && props.data?.length > 0 && props.data.map(function(row: any, rowIdx: number) {
            return(
              <div key={rowIdx} className={props.editRow ? 'app-data-view-row app-data-view-row-hover' : 'app-data-view-row'} 
                onClick={() => {clickAction(props.editRowClick, props.editRow, rowIdx, (!props.validateCreatedBy || (props.validateCreatedBy && appCtxRef?.session?.details?.identifier === row['createdBy'])))}}>
                {props.keys.map(function(col: any, colIdx: number) {
                  return (
                    <span key={colIdx} className='app-data-view-col'>{row[col]} {props.appendunit && props.appendunit[col] && row['unit'] ? (' ' + row['unit']) : ''}</span>
                  );
                })}
                {props.rowIcon && (!props.validateCreatedBy || (props.validateCreatedBy && appCtxRef?.session?.details?.identifier === row['createdBy'])) &&
                  <img src={props.rowIcon} className='app-cursor-pointer' alt='' onClick={($event: any) => {clickAction(props.rowIconClick, props.rowIcon, rowIdx, true); $event.stopPropagation();}}/>
                }
              </div>
            );
          })}
          {props.keys && props.data?.length === 0 &&
            <div className='app-text-gray mt10 ml15'>No Enteries</div>
          }
        </div>
      </div>
    </div>
  );
}
export default DataView;

